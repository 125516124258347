var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Block',{attrs:{"title":_vm.ts >= _vm.payload.end ? 'Results' : 'Current results'}},[_vm._l((_vm.payload.choices),function(choice,i){return _c('div',{key:i},[_c('div',{staticClass:"text-white mb-1"},[_c('span',{staticClass:"mr-1",domProps:{"textContent":_vm._s(_vm._shorten(choice, 'choice'))}}),(_vm.results.totalScores[i])?_c('span',{staticClass:"mr-1"},[_vm._v(" "+_vm._s(_vm._numeral(_vm.results.totalScores[i]))+" "+_vm._s(_vm._shorten(_vm.space.symbol, 'symbol'))+" ")]):_vm._e(),_c('span',{staticClass:"float-right",domProps:{"textContent":_vm._s(
          _vm.$n(
            _vm.results.totalVoteScores
              ? _vm.results.totalScores[i] / _vm.results.totalVoteScores
              : 0,
            'percent'
          )
        )}})]),_c('UiProgress',{staticClass:"mb-3",attrs:{"value":_vm.results.totalScores[i],"max":_vm.results.totalVoteScores,"titles":_vm.titles}})],1)}),(_vm.ts >= _vm.payload.end)?_c('UiButton',{staticClass:"width-full mt-2",on:{"click":_vm.downloadReport}},[_vm._v(" Download report ")]):_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }